import React from "react";
import { arc } from "d3-shape";
import { scaleLinear } from "d3-scale";
import { format } from "d3-format";
import { getGrade } from "../../utils";

const Gauge = ({
  value = 50,
  min = 0,
  max = 100,
  showVal = true,
  label,
  units,
}) => {
  const backgroundArc = arc()
    .innerRadius(0.65)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(1)();

  const redArc = arc()
    .innerRadius(0.75)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 1.9)
    .endAngle(-Math.PI / 4.5)
    .padAngle(0)
    .cornerRadius(2)();

  const orangeArc = arc()
    .innerRadius(0.75)
    .outerRadius(0.9)
    .startAngle(-Math.PI / 5)
    .endAngle(Math.PI / 5)
    .padAngle(0)
    .cornerRadius(2)();

  const greenArc = arc()
    .innerRadius(0.75)
    .outerRadius(0.9)
    .startAngle(Math.PI / 4.5)
    .endAngle(Math.PI / 1.9)
    .padAngle(0)
    .cornerRadius(2)();

  const percentScale = scaleLinear().domain([min, max]).range([0, 1]);
  const percent = percentScale(value);

  const angleScale = scaleLinear()
    .domain([0, 1])
    .range([-Math.PI / 2, Math.PI / 2])
    .clamp(true);

  const angle = angleScale(percent);

  const filledArc = arc()
    .innerRadius(0.65)
    .outerRadius(1)
    .startAngle(-Math.PI / 2)
    .endAngle(angle)
    .cornerRadius(1)();

  const colorScale = scaleLinear().domain([0, 1]).range(["#dbdbe7", "#008000"]);

  const gradientSteps = colorScale.ticks(10).map((value) => colorScale(value));

  const markerLocation = getCoordsOnArc(angle, 1 - (1 - 0.65) / 2);

  const scoreColor = (value) => {
    if (value < 0) return "#808080";
    if (value >= 0 && value <= 33) return "#e81246";
    if (value > 33 && value <= 66) return "#ee8d41";
    if (value > 66 && value <= 100) return "#008000";
  };

  const scoreSize = (value) => {
    if (value < 0) return "text-sm md:text-lg";
    if (value >= 0 && value <= 100) return "text-2xl md:text-3xl";
  };

  return (
    <div className="text-center">
      <svg viewBox={[-1, -1, 2, 1.2].join(" ")}>
        <path d={redArc} fill={value > 0 ? "#e81246" : "#808080"} />
        <path d={orangeArc} fill={value > 0 ? "#ee8d41" : "#808080"} />
        <path d={greenArc} fill={value > 0 ? "#008000" : "#808080"} />
        {value > 0 && (
          <circle
            cx={markerLocation[0]}
            cy={markerLocation[1]}
            r="0.09"
            strokeWidth="0.03"
            fill="white"
            stroke={scoreColor(value)}
          />
        )}
      </svg>

      {showVal && (
        <div
          style={{
            color: scoreColor(value),
            marginTop: "-1em",
            lineHeight: "0.5em",
            fontWeight: "700",
            fontFeatureSettings: "'zero', 'tnum' 1",
          }}
          className={scoreSize(value)}
        >
          {getGrade(value)}
        </div>
      )}

      {!!label && (
        <div
          style={{
            color: scoreColor(value),
            marginTop: "-2em",
            fontSize: "1em",
            lineHeight: "1em",
            fontWeight: "700",
          }}
        >
          {label}
        </div>
      )}

      {!!units && (
        <div
          style={{
            color: "#8b8ba7",
            lineHeight: "1.3em",
            fontWeight: "300",
          }}
        >
          {units}
        </div>
      )}
    </div>
  );
};

const getCoordsOnArc = (angle, offset = 10) => [
  Math.cos(angle - Math.PI / 2) * offset,
  Math.sin(angle - Math.PI / 2) * offset,
];

export default Gauge;
