"use client";
import React from 'react';
import './UsersReport.css';
import PropTypes from 'prop-types';

const UsersReports = ({}) => {
	return (
		<div className='usersreports'>
 			UsersReports works!
 		</div>
	);
};

UsersReports.propTypes = {};

export default UsersReports;