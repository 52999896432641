import "./App.css";
import React, { useEffect, useState } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import Home from "./components/Home/Home";
import Dashboard from "./components/Dashboard/Dashboard";
import Login from "./components/Login/Login";
import AppIndex from "./components/AppIndex/AppIndex";
import Archive from "./components/Archive/Archive";
import MyUsersApps from "./components/MyUsersApps/MyUsersApps";
import AppDetail from "./components/AppDetail/AppDetail";
import Inbox from "./components/Inbox/Inbox";
import Nist from "./components/Nist/Nist";
import Users from "./components/Users/Users";
import Billing from "./components/Billing/Billing";
import Settings from "./components/Settings/Settings";
import NewUser from "./components/NewUser/NewUser";
import FAQ from "./components/FAQ/FAQ";
import ManualUpload from "./components/ManualUpload/ManualUpload";
import Tags from "./components/Tags/Tags";
import ForgotPassword from "./components/ResetPassword/ForgotPassword";
import ResetPassword from "./components/ResetPassword/ResetPassword";

import {
  MenuIcon,
  XIcon,
  UploadIcon,
  ShieldCheckIcon,
  CloudIcon,
  ArchiveIcon,
  QuestionMarkCircleIcon,
  CogIcon,
  TagIcon,
} from "@heroicons/react/outline";
import logo from "./assets/mobstrlogowide.png";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
  Outlet,
} from "react-router-dom";
import ErrorDialog from "./components/Dialog/ErrorDialog";
import axios from "axios";
import ScrollToTop from "./components/Common/ScrollToTop";
import MenuItem from "./components/Menu/MenuItem";
import UsersReport from "./components/UsersReport/UsersReport";

function App() {
  const queryClient = useQueryClient();

  const [userLoggedIn, setUserLoggedIn] = React.useState(null);
  const [isSubUser, setIsSubUser] = React.useState(null);
  const [showLoading, setShowLoading] = React.useState(false);
  const [errorDialogOpen, setErrorDialogOpen] = React.useState(false);

  const { status, data, error, isFetching } = useQuery(["user"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/me`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => {
    setIsOpen((prevState) => !prevState);
  };
  const closeSidebar = () => {
    setIsOpen(false);
  };

  const inbox = useQuery(["inbox"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/inbox`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const myusers = useQuery(["myusers"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myusers`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const myapps = useQuery(["myapps"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myapps`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const myusersapps = useQuery(["myusersapps"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myusersapps`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  const myarchive = useQuery(["myarchive"], () => {
    let token = localStorage.getItem("tokenmob");
    return axios.get(`${process.env.REACT_APP_API_ROOT}/myarchive`, {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
    });
  });

  useEffect(() => {
    if (status === "success" && data && data.data) {
      setUserLoggedIn(data.data);
      if (data.data.trialExpiry) {
        if (new Date() > new Date(data.data.trialExpiry)) {
          setErrorDialogOpen(true);
        }
      }
    } else {
      //doLogOut();
      //Navigate('/login');
    }
  }, [status, data]);

  const doLogOut = () => {
    localStorage.removeItem("tokenmob");
    document.location.href = "/login";
  };

  // // Mutations
  // const mutation = useMutation(postTodo, {
  //   onSuccess: () => {
  //     // Invalidate and refetch
  //     queryClient.invalidateQueries(['todos'])
  //   },
  // })

  return (
    <>
      <Disclosure as="nav" className="bg-white">
        <div className="max-w-full mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative flex items-center justify-between h-24">
            {userLoggedIn && (
              <div className="absolute inset-y-0 left-0 flex items-center md:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button
                  className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                  onClick={toggleSidebar}
                >
                  <span className="sr-only">Open main menu</span>
                  {isOpen ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            )}
            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex sm:items-center">
                <img className="w-44 sm:w-52" src={logo} alt="Workflow" />
                <h2 className="hidden sm:block text-l sm:ml-20 sm:text-2xl">
                  <b>App Intelligence</b> / Security Scanning
                </h2>
              </div>
            </div>
            <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
              <button
                type="button"
                className="bg-white rounded-full text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
              >
                <span className="sr-only">View notifications</span>
                {/* <BellIcon className="h-6 w-6" aria-hidden="true" /> */}
              </button>

              {/* Profile dropdown
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-gray-800 flex text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-8 w-8 rounded-full"
                          src={avatar}
                          alt=""
                        />
                      </Menu.Button>  
                    </div>
                  </Menu>
                  */}
            </div>
          </div>
        </div>
      </Disclosure>

      <Router>
        <ScrollToTop />
        <main>
          <div
            className={`mx-auto px-6 flex flex-row justify-between md:space-x-6 bg-gray-100 ${
              isOpen ? "py-0" : "py-10"
            } sm:${isOpen ? "py-0" : "py-10"}`}
          >
            {userLoggedIn && (
              <>
                <aside
                  className={`md:flex md:w-80 md:flex-col md:inset-y-0 ${
                    isOpen
                      ? "w-screen h-screen bg-gray-100 -mx-6 px-2 overflow-y-auto"
                      : "max-w-xs hidden"
                  }`}
                  aria-label="Sidebar"
                >
                  <div className="overflow-y-auto bg-[#2c4251] rounded dark:bg-gray-800">
                    <ul>
                      <MenuItem
                        displayName="Dashboard"
                        linkPath="dashboard"
                        closeSidebar={closeSidebar}
                      >
                        <svg
                          aria-hidden="true"
                          className="w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                          <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
                        </svg>
                      </MenuItem>

                      <MenuItem
                        displayName="My Apps"
                        linkPath="apps"
                        hasNumber={myapps.data?.data.length}
                        closeSidebar={closeSidebar}
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
                        </svg>
                      </MenuItem>

                      <MenuItem
                        displayName="Archive"
                        linkPath="archive"
                        hasNumber={myarchive.data?.data.length}
                        closeSidebar={closeSidebar}
                      >
                        <ArchiveIcon
                          className={
                            "w-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          }
                        />
                      </MenuItem>

                      {!userLoggedIn.parentId && (
                        <MenuItem
                          displayName="My Users Apps"
                          linkPath="myusersapps"
                          hasNumber={myusersapps.data?.data.length}
                          closeSidebar={closeSidebar}
                        >
                          <CloudIcon
                            className={
                              "w-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            }
                          />
                        </MenuItem>
                      )}
                      {/* <li className=" pt-0 border-b-white border-b">
                        <Link
                          to="/upload"
                          className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
                        >
                          <UploadIcon
                            className={
                              "w-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            }
                          />
                          <span className="flex-1 ml-3 whitespace-nowrap">
                            Upload App
                          </span>
                        </Link>
                      </li> */}
                      <MenuItem
                        displayName="Inbox"
                        linkPath="inbox"
                        hasNumber={inbox.data?.data.length}
                        closeSidebar={closeSidebar}
                      >
                        <svg
                          aria-hidden="true"
                          className="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.707 7.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l2-2a1 1 0 00-1.414-1.414L11 7.586V3a1 1 0 10-2 0v4.586l-.293-.293z"></path>
                          <path d="M3 5a2 2 0 012-2h1a1 1 0 010 2H5v7h2l1 2h4l1-2h2V5h-1a1 1 0 110-2h1a2 2 0 012 2v10a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"></path>
                        </svg>
                      </MenuItem>

                      <MenuItem
                        displayName="Recent Vulnerabilities"
                        linkPath="vuln"
                        closeSidebar={closeSidebar}
                      >
                        <ShieldCheckIcon
                          className={
                            "w-6  text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          }
                        />
                      </MenuItem>

                      {!userLoggedIn.parentId && (
                        <>
                          <MenuItem
                            displayName="Users"
                            linkPath="users"
                            hasNumber={myusers.data?.data.length}
                            closeSidebar={closeSidebar}
                          >
                            <svg
                              aria-hidden="true"
                              className="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                              ></path>
                            </svg>
                          </MenuItem>
                          <MenuItem
                            displayName="My Tags"
                            linkPath="mytags"
                            closeSidebar={closeSidebar}
                          >
                            <TagIcon
                              className={
                                "w-6  text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                              }
                            />
                          </MenuItem>
                        </>
                      )}
                      <MenuItem
                        displayName="FAQ"
                        linkPath="faq"
                        closeSidebar={closeSidebar}
                      >
                        <QuestionMarkCircleIcon
                          className={
                            "w-6  text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                          }
                        />
                      </MenuItem>
                      {userLoggedIn.hasMdm && (
                        <MenuItem
                          displayName="Settings"
                          linkPath="settings"
                          closeSidebar={closeSidebar}
                        >
                          <CogIcon
                            className={
                              "w-6  text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            }
                          />
                        </MenuItem>
                      )}
                      {!userLoggedIn.parentId && (
                        <MenuItem
                          displayName="Billing"
                          linkPath="billing"
                          closeSidebar={closeSidebar}
                        >
                          <svg
                            aria-hidden="true"
                            class="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                            ></path>
                          </svg>
                        </MenuItem>
                      )}
                      {userLoggedIn.email.includes("vambace.co.uk") ||
                        userLoggedIn.email.includes("mobstr.io") ||
                        (userLoggedIn.email.includes("ossia") && (
                          <MenuItem
                            displayName="users report"
                            linkPath="usersreport"
                            closeSidebar={closeSidebar}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              version="1.1"
                              class="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                              fill="currentColor"
                              viewBox="-5.0 -10.0 110.0 135.0"
                            >
                              <path d="M84,2.5H30c-2.9,0-5.3,2.4-5.3,5.3v8.7H16c-2.9,0-5.3,2.4-5.3,5.3v70.5c0,2.9,2.4,5.3,5.3,5.3H70c2.9,0,5.3-2.4,5.3-5.3     v-8.7H84c2.9,0,5.3-2.4,5.3-5.3V7.8C89.2,4.9,86.9,2.5,84,2.5z M71,92.2c0,0.5-0.4,0.9-1,0.9H16c-0.5,0-0.9-0.4-0.9-0.9V21.7     c0-0.5,0.4-0.9,0.9-0.9h8.7v57.5c0,2.9,2.4,5.3,5.3,5.3h41V92.2z M84.9,78.3c0,0.5-0.4,1-1,1H30c-0.5,0-1-0.4-1-1V7.8     c0-0.5,0.4-1,1-1H84c0.5,0,1,0.4,1,1V78.3z" />
                              <path d="M73,53.2H40.9c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2H73c1.2,0,2.2-1,2.2-2.2C75.2,54.2,74.2,53.2,73,53.2z" />
                              <path d="M73,66.2H40.9c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2H73c1.2,0,2.2-1,2.2-2.2C75.2,67.2,74.2,66.2,73,66.2z" />
                              <path d="M73,40.3H40.9c-1.2,0-2.2,1-2.2,2.2c0,1.2,1,2.2,2.2,2.2H73c1.2,0,2.2-1,2.2-2.2C75.2,41.2,74.2,40.3,73,40.3z" />
                              <path d="M44.7,32h3.9c0.5,0,0.8-0.4,0.8-0.8v-6.2c0-0.5-0.4-0.8-0.8-0.8h-3.9c-0.5,0-0.8,0.4-0.8,0.8v6.2     C43.9,31.6,44.3,32,44.7,32z" />
                              <path d="M54.4,32h3.9c0.5,0,0.8-0.4,0.8-0.8V20.5c0-0.5-0.4-0.8-0.8-0.8h-3.9c-0.5,0-0.8,0.4-0.8,0.8v10.6     C53.6,31.6,54,32,54.4,32z" />
                              <path d="M64.1,32H68c0.5,0,0.8-0.4,0.8-0.8v-15c0-0.4-0.4-0.8-0.8-0.8h-3.9c-0.5,0-0.8,0.4-0.8,0.8v15C63.3,31.6,63.7,32,64.1,32     z" />
                            </svg>
                          </MenuItem>
                        ))}
                      <li className="pt-0 border-b-white border-b">
                        <a
                          href="#"
                          className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
                          onClick={doLogOut}
                        >
                          <svg
                            aria-hidden="true"
                            className="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                            ></path>
                          </svg>
                          <span className="flex-1 ml-3 whitespace-nowrap">
                            Log Out
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </aside>
              </>
            )}
            {/* <li className="pt-0 border-b-white border-b">
              <Link
                to="/faq"
                className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
                onClick={closeSidebar}
              >
                <QuestionMarkCircleIcon
                  className={
                    "w-6  text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  }
                />
                <span className="flex-1 ml-3 whitespace-nowrap">
                  FAQ
                </span>
              </Link>
            </li>
            {!userLoggedIn.parentId && (
              <li className="pt-0 border-b-white border-b">
                <Link
                  to="/billing"
                  className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
                  onClick={closeSidebar}
                >
                  <svg
                    aria-hidden="true"
                    className="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10 2a4 4 0 00-4 4v1H5a1 1 0 00-.994.89l-1 9A1 1 0 004 18h12a1 1 0 00.994-1.11l-1-9A1 1 0 0015 7h-1V6a4 4 0 00-4-4zm2 5V6a2 2 0 10-4 0v1h4zm-6 3a1 1 0 112 0 1 1 0 01-2 0zm7-1a1 1 0 100 2 1 1 0 000-2z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                  <span className="flex-1 ml-3 whitespace-nowrap">
                    Billing
                  </span>
                </Link>
              </li>
            )}

            <li className="pt-0 border-b-white border-b">
              <a
                href="#"
                className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
                onClick={doLogOut}
              >
                <svg
                  aria-hidden="true"
                  className="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Log Out
                </span>
              </a>
            </li> */}
            {/* // No need to show if not logged in
      {!userLoggedIn && (
          <li className=" border-b-white border-b">
            <a
              href="/"
              className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"></path>
              </svg>
              <span className="ml-3">Home</span>
            </a>
          </li>
          <li className=" border-b-white border-b">
            <a
              href="/login"
              className="flex items-center p-4 text-base font-normal text-white rounded-lg dark:text-white hover:bg-gray-600 dark:hover:bg-gray-700"
            >
              <svg
                aria-hidden="true"
                className="flex-shrink-0 w-6 h-6 text-white transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="ml-3">Login</span>
            </a>
          </li>
          )}
      */}
            {/* </ul>  */}

            {/* <div
      id="dropdown-cta"
      className="p-4 mt-6 bg-blue-50 rounded-lg dark:bg-blue-900 m-4"
      role="alert"
    >
      <div className="flex items-center mb-3">
        <span className="bg-orange-100 text-orange-800 text-sm font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-orange-200 dark:text-orange-900">
          Beta
        </span>
        <button
          type="button"
          className="ml-auto -mx-1.5 -my-1.5 bg-blue-50 text-blue-900 rounded-lg focus:ring-2 focus:ring-blue-400 p-1 hover:bg-blue-200 inline-flex h-6 w-6 dark:bg-blue-900 dark:text-blue-400 dark:hover:bg-blue-800"
          data-collapse-toggle="dropdown-cta"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>
          <svg
            aria-hidden="true"
            className="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <p className="mb-3 text-sm text-blue-900 dark:text-blue-400">
        You are previewing our new beta application security scanning
        system. To add and scan your own apps, contact us to talk
        about a free trial
      </p>
      <a
        className="text-sm text-blue-900 underline hover:text-blue-800 dark:text-blue-400 dark:hover:text-blue-300"
        href="#"
      >
        Click Here To Enquire
      </a>
    </div> */}
            {/* </div>
      </aside>
    </>
  )} */}

            <div className={`${isOpen ? "hidden" : "w-full"}`}>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/login"
                  element={<Login setShowLoading={setShowLoading} />}
                />
                <Route path="/newuser" element={<NewUser />} />
                <Route path="/forgotpassword" element={<ForgotPassword />} />
                <Route path="/resetpassword" element={<ResetPassword />} />

                <Route
                  element={
                    <ProtectedRoute
                      setShowLoading={setShowLoading}
                      isAllowed={localStorage.getItem("tokenmob")}
                    />
                  }
                >
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/apps" element={<AppIndex />} />
                  <Route path="/archive" element={<Archive />} />
                  <Route path="/mytags" element={<Tags />} />
                  <Route
                    path="/myusersapps"
                    element={<MyUsersApps queryClient={queryClient} />}
                  />
                  <Route path="/inbox" element={<Inbox />} />
                  <Route path="/vuln" element={<Nist />} />
                  <Route path="/faq" element={<FAQ />} />
                  <Route path="/usersreport" element={<UsersReport />} />
                  <Route path="/billing" element={<Billing />} />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/users" element={<Users />} />
                  <Route
                    path="/upload"
                    element={<ManualUpload setShowLoading={setShowLoading} />}
                  />
                  <Route
                    path="/app/:bundleId"
                    element={<AppDetail setShowLoading={setShowLoading} />}
                  />
                </Route>
                <Route path="*" element={<p>There's nothing here: 404!</p>} />
              </Routes>
            </div>
          </div>

          {showLoading && (
            <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
              <h2 className="text-center text-white text-xl font-semibold">
                Please Wait...
              </h2>
              <p className="w-1/3 text-center text-white"></p>
            </div>
          )}
          <ErrorDialog
            open={errorDialogOpen}
            onClose={() => doLogOut()}
            error={"Error"}
            children={
              <p>
                Your trial has expired. Please get in touch to extend or
                purchase this service. Thanks!
              </p>
            }
            onConfirm={() => doLogOut()}
          />
        </main>
      </Router>
    </>
  );
}

const ProtectedRoute = ({
  isAllowed,
  redirectPath = "/login",
  children,
  setShowLoading,
}) => {
  if (!isAllowed) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet setShowLoading={setShowLoading} />;
};

export default App;
